import React from "react";

import Layout from "../components/layout";

export default () => {
	const ArticleSummaryBox = ({
		title,
		author,
		publishedDate,
		description,
		href,
	}) => {
		return (
			<section className="container maxWidth-800 mb-90">
				<a href={href}>
					<h2>{title}</h2>
				</a>
				<div className="text-xsmall verticalList mb-15">
					<div className="u-text-bold mr-5">{author}</div>
					<div className="text-light">{publishedDate}</div>
				</div>
				<div className="text-small mb-30">{description}...</div>
				<a
					className="button button-primary button-small text-small"
					href={href}>
					Read more →
				</a>
			</section>
		);
	};

	return (
		<Layout
			pageMeta={{
				title: "Articles",
			}}>
			<main>
				<header className="blog text-center mb-90">
					<div className="headerWithBg mb-50">
						<div className="container">
							<h1>Articles</h1>
							<p className="maxWidth-750 text-large text-dark itemCenter">
								Learn about SaaS emails, best practices for sending
								transactional emails, writing SaaS marketing emails, setting up
								email automations, creating email templates, working with email
								sending APIs & email providers, and more...
							</p>
						</div>
					</div>
				</header>

				<ArticleSummaryBox
					title="The Importance of Domain Reputation in Email Deliverability"
					author="Patrik"
					publishedDate="published in March 2025"
					description="In the early days of email spam filtering, the sending server’s IP address was the primary identifier used to judge trustworthiness. However, modern email filtering has evolved to focus more on domain..."
					href="/articles/domain-reputation/"
				/>

				<ArticleSummaryBox
					title="What is a transactional email?"
					author="Kristyna"
					publishedDate="published in February 2025"
					description="Transactional emails play a crucial role in digital platforms, from SaaS applications to e-commerce stores. They’re triggered by specific user actions and deliver timely, relevant information that keeps users"
					href="/articles/what-is-transactional-email/"
				/>

				<ArticleSummaryBox
					title="Top Brevo Alternatives in 2025"
					author="Kristyna"
					publishedDate="published in January 2025"
					description="Looking for a better alternative to Brevo (formerly Sendinblue)? Whether you’re managing transactional emails, marketing automations, or just seeking more reliable support, there are several competitive"
					href="/articles/brevo-alternatives/"
				/>

				<ArticleSummaryBox
					title="Transactional Email vs Marketing Email – What’s the Difference + Explanation + Examples"
					author="Kristyna"
					publishedDate="published in June 2024"
					description="Transactional emails and marketing emails – you have probably heard these terms before. However, the definitions are not always very clear, which might leave you unsure about the difference and why"
					href="/articles/transactional-email-vs-marketing-email/"
				/>

				<ArticleSummaryBox
					title="What is an Email API? Everything you need to know"
					author="Kristyna"
					publishedDate="published in May 2024"
					description="Are you building an online business, digital product, application, SaaS, or something similar? Then you’ll probably need an email API—to send transactional emails like password resets or receipts, set up"
					href="/articles/what-is-email-api/"
				/>

				<ArticleSummaryBox
					title="8 Best Transactional Email Platforms in 2025 Compared"
					author="Kristyna"
					publishedDate="updated in January 2025"
					description="Single sign-on, password reset, welcome email, order confirmation – these all are transactional emails. Emails that are sent as a direct response to a user’s actions in an application, service, or"
					href="/articles/best-transactional-email-platform/"
				/>

				<ArticleSummaryBox
					title="SendGrid Alternatives with Better Customer Support & Deliverability"
					author="Kristyna"
					publishedDate="updated in January 2025"
					description="Tired of dealing with SendGrid's poor customer support? Struggling with the deliverability of your transactional emails? Read this detailed comparison between Sendgrid and other email providers"
					href="/articles/sendgrid-alternatives/"
				/>

				<ArticleSummaryBox
					title="Simple Amazon SES Alternatives for SaaS"
					author="Kristyna"
					publishedDate="updated in January 2025"
					description="Amazon SES provides you with low-level tools for email delivery, but all the integration, email template setup, troubleshooting, analytics, etc. is up to you. Find a simpler Amazon SES alternative for your SaaS"
					href="/articles/amazon-ses-alternatives/"
				/>

				<ArticleSummaryBox
					title="The Best Mandrill Alternatives for Delivering & Managing SaaS Emails"
					author="Kristyna"
					publishedDate="updated in July 2024"
					description="Mailchimp Transactional Emails, formerly Mandrill, is a paid Mailchimp add-on for sending transactional emails. If you're looking for a better alternative to Mandrill, check this detailed comparison for 2023"
					href="/articles/mandrill-alternatives/"
				/>

				<ArticleSummaryBox
					title="Mailgun Alternatives for SaaS with Better Customer Support & Reliability"
					author="Kristyna"
					publishedDate="updated in January 2025"
					description="Looking for a better alternative to Mailgun? Need a complete solution for your SaaS? We’re bringing you a detailed comparison between Mailgun and other email providers for 2023"
					href="/articles/mailgun-alternatives/"
				/>

				<ArticleSummaryBox
					title="The Best Postmark Alternatives for Sending SaaS Emails"
					author="Kristyna"
					publishedDate="updated in January 2025"
					description="Need to deliver not only transactional emails but also sent product updates and newsletters? And set up a welcome email sequence or prevent churns with email automation? Compare Postmark alternatives"
					href="/articles/postmark-alternatives/"
				/>

				<ArticleSummaryBox
					title="Dark mode in HTML email"
					author="Patrik"
					publishedDate="updated in March 2020"
					description="The new big trend in tech, dark mode, is coming to email. Learn how it works and how to make dark‑mode‑friendly HTML emails yourself"
					href="/articles/dark-mode-in-html-email/"
				/>

				<section className="blog">
					<div className="subscribeCta lightBlueBg">
						<div className="container maxWidth-800">
							<div className="fancyLabel">About Sidemail</div>

							<h2>Need to deliver emails? 💌</h2>
							<p>
								Sidemail is an all-in-one email platform for SaaS. We'll help
								you with delivering transactional emails via API, sending
								marketing emails, setting up automated email sequences, and
								managing contacts. Simple instructions. Quick setup.
							</p>
							<p>
								Create your account now and start sending your emails in under
								30 minutes.
							</p>

							<a className="button button-primary button-large" href="/">
								Learn more →
							</a>
						</div>
					</div>
				</section>
			</main>
		</Layout>
	);
};
